import React from "react";

const svgIcon = (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9539 8.27797C22.8488 7.94441 22.5549 7.69746 22.2061 7.64678L15.3855 6.65576L12.3328 0.473741C12.0204 -0.157914 10.9824 -0.157914 10.6663 0.473741L7.61724 6.65576L0.796656 7.64678C0.448347 7.69746 0.154404 7.94072 0.0452123 8.27797C-0.063519 8.61568 0.0272439 8.98611 0.281104 9.22937L5.21778 14.0426L4.0526 20.8341C3.99455 21.1829 4.13646 21.5386 4.42303 21.7454C4.7096 21.9523 5.08739 21.9813 5.39931 21.8141L11.5016 18.6088L17.5998 21.8141C17.7375 21.8869 17.8864 21.9196 18.0352 21.9196C18.2278 21.9196 18.4166 21.8615 18.5797 21.7454C18.8668 21.5349 19.0082 21.1829 18.9502 20.8341L17.785 14.0426L22.7217 9.22937C22.9755 8.98242 23.0631 8.61568 22.9539 8.27797Z"
      fill="currentColor"
    />
  </svg>
);

const Stars = ({ rating = 5 }) => {
  return (
    <div className="fstars">
      {[...Array(5).keys()].map((n, index) => (
        <div
          className={`fstars-star ${index + 1 <= rating ? "_fill" : ""}`}
          key={index}
        >
          {svgIcon}
        </div>
      ))}
    </div>
  );
};

export default Stars;
