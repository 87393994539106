export const border1 = (
  <svg
    width="220"
    height="190"
    viewBox="0 0 213 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M37.8646 0C20.4013 0 6.24219 13.5547 6.24219 30.2725V79.8694C6.24219 80.6936 6.9409 81.3625 7.8019 81.3625C8.66289 81.3625 9.3616 80.6936 9.3616 79.8694V30.2682C9.3616 15.1988 22.1188 2.98627 37.8601 2.98627H113.5V0H37.8646Z"
      fill="#956DF8"
    />
    <path
      d="M174.359 0H112.354V2.98627H174.359C190.1 2.98627 202.857 15.2031 202.857 30.2682V79.8694C202.857 80.6936 203.556 81.3625 204.417 81.3625C205.278 81.3625 205.977 80.6936 205.977 79.8694V30.2682C205.981 13.5504 191.822 0 174.359 0Z"
      fill="#956DF8"
    />
    <path
      d="M212.219 95.6046C212.219 91.2979 208.388 87.8283 203.817 88.1562C200.035 88.4281 196.969 91.3281 196.645 94.9444C196.37 97.9824 198.006 100.688 200.531 102.082C201.955 102.867 202.857 104.309 202.857 105.884V160.94C202.857 176.009 190.095 188.222 174.358 188.222H37.8612C22.1199 188.222 9.36275 176.009 9.36275 160.94V105.884C9.36275 104.309 10.2598 102.867 11.6888 102.082C14.2132 100.688 15.8495 97.9824 15.5745 94.9444C15.2455 91.3281 12.1801 88.4281 8.40259 88.1562C3.83165 87.8283 0 91.2979 0 95.6046C0 98.3924 1.60478 100.831 3.9759 102.112C5.38685 102.876 6.24333 104.33 6.24333 105.884V160.94C6.24333 177.658 20.4024 191.212 37.8657 191.212H174.358C191.822 191.212 205.981 177.658 205.981 160.94V105.884C205.981 104.33 206.833 102.876 208.248 102.112C210.615 100.831 212.219 98.3924 212.219 95.6046ZM3.12392 95.6046C3.12392 93.1319 5.22457 91.1209 7.80755 91.1209C10.3905 91.1209 12.4912 93.1319 12.4912 95.6046C12.4912 98.0774 10.3905 100.088 7.80755 100.088C5.22457 100.088 3.12392 98.0774 3.12392 95.6046ZM204.416 100.088C201.833 100.088 199.733 98.0774 199.733 95.6046C199.733 93.1319 201.833 91.1209 204.416 91.1209C206.999 91.1209 209.1 93.1319 209.1 95.6046C209.1 98.0774 206.999 100.088 204.416 100.088Z"
      fill="#956DF8"
    />
    <path
      d="M204.415 98.4277C206.043 98.4277 207.363 97.1642 207.363 95.6055C207.363 94.0468 206.043 92.7832 204.415 92.7832C202.787 92.7832 201.467 94.0468 201.467 95.6055C201.467 97.1642 202.787 98.4277 204.415 98.4277Z"
      fill="#956DF8"
    />
  </svg>
);
export const border2 = (
  <svg
    width="220"
    height="190"
    viewBox="0 0 213 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M38.5324 0.00195312C21.0691 0.00195312 6.91 13.5566 6.91 30.2745V79.8713C6.91 80.6956 7.6087 81.3645 8.46969 81.3645C9.33069 81.3645 10.0294 80.6956 10.0294 79.8713V30.2702C10.0294 15.2008 22.7911 2.98823 38.5279 2.98823H115.5V0.00195312H38.5324Z"
      fill="#956DF8"
    />
    <path
      d="M175.023 0.00195312H113.662V2.98823H175.023C190.764 2.98823 203.521 15.2051 203.521 30.2702V71.0722C203.521 71.8965 204.22 72.5654 205.081 72.5654C205.942 72.5654 206.641 71.8965 206.641 71.0722V30.2702C206.645 13.5523 192.491 0.00195312 175.023 0.00195312Z"
      fill="#956DF8"
    />
    <path
      d="M212.892 86.8099C212.892 82.5031 209.06 79.0335 204.489 79.3615C200.707 79.6334 197.642 82.5334 197.317 86.1453C197.042 89.1834 198.679 91.8891 201.207 93.283C202.632 94.0684 203.533 95.5097 203.533 97.0849V97.8962H206.653V97.0849C206.653 95.5313 207.505 94.077 208.92 93.3132C211.287 92.0315 212.892 89.5976 212.892 86.8099ZM205.089 91.2893C202.506 91.2893 200.405 89.2783 200.405 86.8056C200.405 84.3329 202.506 82.3219 205.089 82.3219C207.672 82.3219 209.772 84.3329 209.772 86.8056C209.772 89.2783 207.672 91.2893 205.089 91.2893Z"
      fill="#956DF8"
    />
    <path
      d="M205.089 89.631C206.717 89.631 208.037 88.3675 208.037 86.8088C208.037 85.2501 206.717 83.9865 205.089 83.9865C203.461 83.9865 202.141 85.2501 202.141 86.8088C202.141 88.3675 203.461 89.631 205.089 89.631Z"
      fill="#956DF8"
    />
    <path
      d="M212.891 103.873C212.891 99.5663 209.06 96.0967 204.489 96.4247C200.707 96.6965 197.641 99.5965 197.317 103.213C197.042 106.251 198.678 108.957 201.207 110.35C202.632 111.136 203.533 112.577 203.533 114.152V160.94C203.533 176.009 190.771 188.222 175.035 188.222H38.5331C22.7918 188.222 10.0346 176.005 10.0346 160.94V105.884C10.0346 104.309 10.9317 102.868 12.3607 102.082C14.885 100.688 16.5214 97.9825 16.2464 94.9445C15.9173 91.3282 12.852 88.4282 9.07447 88.1564C4.50353 87.8284 0.671875 91.298 0.671875 95.6048C0.671875 98.3925 2.27666 100.831 4.64778 102.112C6.05873 102.876 6.9152 104.33 6.9152 105.884V160.94C6.9152 177.658 21.0743 191.212 38.5376 191.212H175.03C192.493 191.212 206.652 177.658 206.652 160.94V114.148C206.652 112.594 207.504 111.14 208.92 110.376C211.287 109.095 212.891 106.661 212.891 103.873ZM3.79128 95.6048C3.79128 93.132 5.89194 91.121 8.47492 91.121C11.0579 91.121 13.1586 93.132 13.1586 95.6048C13.1586 98.0775 11.0579 100.088 8.47492 100.088C5.89194 100.088 3.79128 98.0775 3.79128 95.6048ZM205.088 108.352C202.505 108.352 200.405 106.341 200.405 103.869C200.405 101.396 202.505 99.385 205.088 99.385C207.671 99.385 209.772 101.396 209.772 103.869C209.772 106.341 207.671 108.352 205.088 108.352Z"
      fill="#956DF8"
    />
    <path
      d="M205.089 106.694C206.717 106.694 208.037 105.43 208.037 103.871C208.037 102.313 206.717 101.049 205.089 101.049C203.461 101.049 202.141 102.313 202.141 103.871C202.141 105.43 203.461 106.694 205.089 106.694Z"
      fill="#956DF8"
    />
  </svg>
);
export const border3 = (
  <svg
    width="220"
    height="190"
    viewBox="0 0 213 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M38.1948 0.00195312C20.7314 0.00195312 6.57236 13.5566 6.57236 30.2745V79.8713C6.57236 80.6956 7.27111 81.3645 8.1321 81.3645C8.9931 81.3645 9.69179 80.6956 9.69179 79.8713V30.2702C9.69179 15.2008 22.4534 2.98823 38.1903 2.98823H115V0.00195312H38.1948Z"
      fill="#956DF8"
    />
    <path
      d="M204.753 115.488C206.381 115.488 207.701 114.225 207.701 112.666C207.701 111.107 206.381 109.844 204.753 109.844C203.125 109.844 201.805 111.107 201.805 112.666C201.805 114.225 203.125 115.488 204.753 115.488Z"
      fill="#956DF8"
    />
    <path
      d="M174.691 0.00227356H112.623V2.98855H174.691C190.433 2.98855 203.19 15.2054 203.19 30.2705V62.8043C203.19 63.6285 203.889 64.2974 204.75 64.2974C205.611 64.2974 206.309 63.6285 206.309 62.8043V30.2705C206.314 13.5526 192.155 0.00227356 174.691 0.00227356Z"
      fill="#956DF8"
    />
    <path
      d="M212.546 78.5424C212.546 74.2357 208.714 70.7661 204.143 71.0941C200.361 71.3659 197.296 74.2659 196.971 77.8779C196.696 80.9159 198.333 83.6216 200.857 85.0155C202.282 85.8009 203.183 87.2423 203.183 88.8174V89.6287H206.303V88.8174C206.303 87.2639 207.155 85.8096 208.57 85.0457C210.941 83.7684 212.546 81.3302 212.546 78.5424ZM204.743 83.0261C202.16 83.0261 200.059 81.0151 200.059 78.5424C200.059 76.0697 202.16 74.0587 204.743 74.0587C207.326 74.0587 209.427 76.0697 209.427 78.5424C209.427 81.0151 207.326 83.0261 204.743 83.0261Z"
      fill="#956DF8"
    />
    <path
      d="M204.749 81.3656C203.122 81.3656 201.801 80.1011 201.801 78.5433C201.801 76.9854 203.122 75.721 204.749 75.721C206.376 75.721 207.697 76.9854 207.697 78.5433C207.697 80.1011 206.376 81.3656 204.749 81.3656Z"
      fill="#956DF8"
    />
    <path
      d="M212.546 95.6049C212.546 91.2982 208.714 87.8286 204.143 88.1566C200.361 88.4284 197.296 91.3284 196.971 94.9447C196.696 97.9827 198.333 100.688 200.857 102.082C202.282 102.868 203.183 104.309 203.183 105.884V106.696H206.303V105.884C206.303 104.331 207.155 102.876 208.57 102.113C210.941 100.831 212.546 98.3927 212.546 95.6049ZM204.743 100.089C202.16 100.089 200.059 98.0777 200.059 95.6049C200.059 93.1322 202.16 91.1212 204.743 91.1212C207.326 91.1212 209.427 93.1322 209.427 95.6049C209.427 98.0777 207.326 100.089 204.743 100.089Z"
      fill="#956DF8"
    />
    <path
      d="M204.749 98.4281C206.377 98.4281 207.697 97.1645 207.697 95.6058C207.697 94.0471 206.377 92.7835 204.749 92.7835C203.121 92.7835 201.801 94.0471 201.801 95.6058C201.801 97.1645 203.121 98.4281 204.749 98.4281Z"
      fill="#956DF8"
    />
    <path
      d="M212.549 112.668C212.549 108.361 208.717 104.892 204.146 105.22C200.364 105.492 197.299 108.391 196.974 112.008C196.699 115.046 198.336 117.752 200.86 119.145C202.285 119.931 203.186 121.372 203.186 122.947V160.94C203.186 176.009 190.425 188.222 174.688 188.222H38.1952C22.4539 188.222 9.69675 176.005 9.69675 160.94V105.884C9.69675 104.309 10.5938 102.868 12.0228 102.082C14.5472 100.688 16.1835 97.9827 15.9085 94.9447C15.5795 91.3284 12.5141 88.4284 8.73658 88.1566C4.16564 87.8286 0.333984 91.2982 0.333984 95.6049C0.333984 98.3927 1.9388 100.831 4.30992 102.113C5.72087 102.876 6.57731 104.331 6.57731 105.884V160.94C6.57731 177.658 20.7364 191.213 38.1997 191.213H174.692C192.156 191.213 206.31 177.662 206.31 160.944V122.952C206.31 121.398 207.162 119.944 208.578 119.18C210.944 117.894 212.549 115.456 212.549 112.668ZM3.44891 95.6049C3.44891 93.1322 5.54956 91.1212 8.13255 91.1212C10.7155 91.1212 12.8162 93.1322 12.8162 95.6049C12.8162 98.0777 10.7155 100.089 8.13255 100.089C5.54956 100.089 3.44891 98.0777 3.44891 95.6049ZM204.746 117.152C202.163 117.152 200.062 115.141 200.062 112.668C200.062 110.195 202.163 108.184 204.746 108.184C207.329 108.184 209.429 110.195 209.429 112.668C209.429 115.141 207.329 117.152 204.746 117.152Z"
      fill="#956DF8"
    />
  </svg>
);
